html,
body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

#root {
  height: 100%;
}

#logoscroll {
  height: 150px;
  margin-bottom: 30px;
}

.App {
  height: 100%;
}

.navTrans {
  background-color: rgba(255,255,255,0);
  border: none;
  height: 80px;
  display: flex;
  transition: all 0.5s ease-in-out;
}

.navRightEight {
  height: 80px;
}

.landing {
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  background-image: linear-gradient(to right bottom, #64b5f6, #51a5ee, #3e96e5, #2c86dc, #1976d2);
  color: white;
  z-index: 4;
}

.landingAbsolute {
  position: absolute;
  top: 0;
}

.landingWhite {
  width: 50%;
  height: 100%;
}

.landingMap {
  width: 50%;
  right: 0;
  height: 100%;
}

.landingSocial {
  transition: 0.25s ease-in;
}

.landingSocial:hover {
  color: rgb(255, 235, 59);
}

.socialContain {
  position: absolute;
  bottom: 0;
  font-size: 28px;
  padding-bottom: 20px;
  z-index: 6;
}

.fauxDonut {
  display: flex;
  justify-content: center;
  margin-top: 35%;
  height: 100%;
}

.landingColContain {
  height: 100%;
}

.bannerVis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.banner_vis_contain {
  width: 100%;
  height: 450px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.wasa {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.landingColumn {
  height: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project_slider_box {
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.35s ease-in-out;
}

.project_slider_box:hover {
  border-color: #bdbdbd;
}

.project_slider_title {
  margin-top: 15px;
  font-weight: 600;
  color: #616161;
}

.project_slider_image {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.project_main_banner {
  display: flex;
  justify-content: center;
}

.project_main_picture {
  box-shadow: 0 0 12px 3px rgba(0,0,0,0.16);
  border-radius: 10px;
  width: 415px;
  height: 415px;
}

.project_details {
  padding: 0px 25px;
}

.thumbnail_gradient_two {
  padding-top: 75px;
}

.thumnail_gradient {
  background-image: linear-gradient(to right top, #f5f5f5, #f3f3f3, #f1f2f1, #f0f0f0, #eeeeee);
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-pagination-bullet-active {
  background: #616161;
}



.projButt {
  border: none;
  color: white;
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  margin-bottom: 0px;
  padding-bottom: 3px;
  border-bottom: 1px solid;
  /* ...and now for the proper property */
  transition:.5s;
}

.projButtColor {
  color: #57068c;
  margin-bottom: 0px;
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  padding-bottom: 3px;
  border-bottom: 1px solid;
  border-color: #57068c;
  transition:.5s;
}

.startButt {
  color: white;
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  /* ...and now for the proper property */
  transition:.5s;
}

.startButt:hover {
  color: #FFEB3B;
}

.Butt {
  border: none;
  font-size: 16px;
  padding: 0px;
  cursor: pointer;
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  border-bottom: 1px solid;
  /* ...and now for the proper property */
  transition:.5s;
  align-self: flex-start;
  font-weight: 600;
  color: #2196f3;
  margin-top: 10px;
}

.teamAvatar {
  height: 160px;
  border-radius: 50%;
  margin-right: 30px;
  align-self: center;
}

.mobilepic {
  display: none;
}

.footer {
  background: #57068c;
  background-image: linear-gradient(to right top, #1976d2, #156ac6, #125eba, #0f53ad, #0d47a1);
  padding: 50px 0px;
  color: white;
}

.projButton {
  margin-top: -50px;
  width: 100%;
}

.about {
  padding: 50px 0px 70px 0px;
}

.teamMemberContainer {
  display: flex;
}

.imageContain {
  padding: 20px 50px 20px 50px;
}

.imageCompareContain {
  padding: 30px 50px 30px 50px;
}

.containProjectTitle {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 60px;
}

.projTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 45px;
}

.projDesc {
  font-size: 14px;
  margin: 10px 0px;
}

.whoami {
  font-size: 16px;
  color: #616161;
}

.smalldisplay {
  display: none;
}

.aboutPlotree {
  font-size: 16px;
  margin-top: 15px;
}

.landingBanner, .landingTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 400;
}

.landingTitle {
  font-size: 50px;
}

.landingBanner {
  margin-top: 10px;
  color: #ffeb3b;
}

.foot_heading {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  color: #ffeb3b;
}

.foot_section_heading {
  color: #ffeb3b;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  margin-top: 30px;
}

.footer_social {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.footer_social:hover {
  color: #ffeb3b;
}

.copyright_message {
  color: #ffeb3b;
  margin-top: 30px;
  font-weight: 600;
}

.value_prop {
  margin-bottom: 50px;
}

.landingDesc {
  margin-top: 40px;
}

.eighteen {
  font-size: 16px;
}

.twenty {
  font-size: 18px;
}

.companyBanner {
  font-size: 36px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: #1976d2;
  margin-bottom: 20px;
}

.getintouch {
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #757575;
  margin-top: 40px;
}

.proj_section_heading {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #1976d2;
  margin-bottom: 40px;
}

.moreprojects {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #757575;
  font-size: 14px;
  margin-top: 35px;
}

.myname, .mydesig {
  font-family: 'Roboto', sans-serif;
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 600;
}

.myname {
  font-size: 26px;
}

.mydesig {
  font-size: 18px;
  color: #1976d2;
}

.team_first_row {
  margin-top: 30px;
}

.team_second_row {
  margin-top: 30px;
}

.youtubeParent {
  padding-top: 30px;
  display: flex;
}

.youtubeContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 12px 3px rgba(0,0,0,0.16);
}

.responsive_youtube {
  width: 30%;
  padding-left: 25px;
  display: flex;
  color: #616161;
  align-items: center;
}

.fulbright_talk {
  margin-top: 50px;
}

.frank_ruhl {
  font-family: 'Frank Ruhl Libre', serif;
}

.twenty_t {
  font-size: 20px;
  color: black;
}

.grey {
  color: #616161;
}

@media only screen and (max-width: 1200px) {
  .myname {
    font-size: 24px;
    font-weight: 500;
  }
  .mydesig {
    font-size: 18px;
    font-weight: 500;
  }
  .project_main_picture {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .landingTitle {
    font-size: 40px;
  }
  .fauxDonut {
    margin-top: 50%;
  }
  .landingBanner {
    font-size: 35px;
  }
  .project_main_picture {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 991px) {
  .project_details {
    padding: 0px 5px;
  }
  .youtubeParent {
    flex-direction: column-reverse;
    padding-top: 10px;
  }

  .responsive_youtube {
    padding-left: 0px;
    width: 100%;
    padding-bottom: 30px;
  }

  .youtubeContain {
    width: 100%;
  }
}


@media only screen and (max-width: 550px) {
  .fauxDonut {
    margin-top: 50%;
  }
  .landingTitle {
    font-size: 40px;
  }
  .landingBanner {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    align-items: baseline;
  }

  .projButt, .projButtColor {
    display: none;
  }

  .bannerVis {
    display: none;
  }

  .landingWhite {
    width: 100%;
  }

  .desktoppic {
    display: none;
  }

  .mobilepic {
    display: block;
  }

  .imageContain {
    padding: 25px;
  }

  .imageCompareContain {
    padding: 20px;
  }

  .teamAvatar {
    height: 130px;
  }

  .whoami {
    font-size: 14px;

  }

  .myname {
    font-size: 20px;
  }

  .mydesig {
      font-size: 16px;
  }

  .containProjectTitle {
    padding: 15px 50px !important;
  }
  .evenProj {
    padding-top: 75px;
  }

  .twenty {
    font-size: 16px;
  }

  .twenty_t {
    font-size: 18px;
  }

  .companyBanner {
    font-size: 32px;
  }
}


@media only screen and (max-width: 540px) {
  .navTrans {
    display: block;
  }
  .navbar-brand {
    padding: 17px 20px;
  }
  .project_main_picture {
    width: 325px;
    height: 325px;
  }

}

@media only screen and (max-width: 380px) {
  .whoami {
    font-size: 12px;
  }
  .teamAvatar {
    height: 100px;
  }
  .aboutPlotree {
    font-size: 14px;
  }
  .landingTitle {
    font-size: 30px;
  }
  .landingBanner {
    font-size: 30px;
  }
  #logoscroll {
    height: 100px;
  }
  .containProjectTitle {
    padding: 15px 35px !important;
  }
  .PakMap {
    height: 80% !important;
  }
  .projTitle {
    font-size: 16px;
  }
  .projDesc {
    font-size: 12px;
  }
  .Butt {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .fauxDonut {
    margin-top: 35%;
  }
  .landingTitle {
    font-size: 35px;
  }
  .landingBanner {
    font-size: 30px;
  }
}

@media only screen and (max-width: 850px) and (orientation: landscape) {
  .landing {
    height: 575px;
  }
  .teamAvatar {
    height: 120px;
  }
  .myname {
    font-size: 20px;
  }
  .mydesig {
    font-size: 18px;
  }
  .whoami {
    font-size: 14px;
  }
}

@media only screen and (max-width: 750px) and (orientation: landscape) {
  .fauxDonut {
    margin-top: 20%;
  }
}
